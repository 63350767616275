<template>
    <div class="route-container">
        <punch-card
            class="u-m-t-30"
            txtTag="lineTitle"
            imgTag="logoUrl"
            src="/route/details"
            :list="humanisticList"
        ></punch-card>
    </div>
</template>

<script>
import { getLineHumanisticList } from "@/service";
import punchCard from "@/components/card/punch-card.vue";
import footGuide from "@/components/footer/footGuide";
export default {
    name: "routePage",
    components: {
        punchCard,
        footGuide,
    },
    data() {
        return {
            humanisticList: [],
        };
    },
    mounted() {
        this.getLineHumanisticList();
    },
    methods: {
        async getLineHumanisticList() {
            const params = {
                pageNo: 1,
                pageSize: 1000,
                commonId: "",
            };
            const { data } = await getLineHumanisticList(params);
            this.humanisticList = data.list;
        },
    },
};
</script>

<style lang="scss" scoped>
.route-container {
    padding: 10px 36px 120px;
    background: #fff;
}
</style>